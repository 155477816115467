import { useState } from 'react';
import logo from './assets/img/logo.png';
import WorkItems from './assets/data/work.data';
import './App.scss';
import WorkItem from './components/WorkItem';
import file from './assets/data/Tuttle_Resume.pdf'


const App = () => {
  const [workItems] = useState(WorkItems);

  const openInNewTab = (url:string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const openLinkedIn = () => {
    const newWindow = window.open('https://www.linkedin.com/in/nstuttle/', '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} className="logo" alt="logo" />
        <div className="links">
          <a href="#about">About</a>
          <a href="#work">Work</a>
          <a href="#contact">Contact</a>
        </div>
      </header>
      <div id="about" className="section">
        <div className="section-heading">
          <h2>About</h2>
        </div>
        <div className="section-content">
          <p>Hello, I'm Nick, a seasoned full-stack developer and designer. I specialize in Angular and
            React, API integration, UX/UI design, and robust stack architecture with a strong track
            record in the web application space. I have also founded HighPine Media, building web
            services enabled for small business. Beyond tech, I'm an outdoor enthusiast, fly fisher,
            DIYer, and music enthusiast, constantly bringing creativity and innovation to all aspects of
            my life.
          </p>
        </div>
      </div>
      <div className="resume-block">
            <button onClick={()=> {openInNewTab(file)}}>Get Resume</button>
          </div>
      <div id="work" className="section">
        <div className="section-heading">
          <h2>Experience</h2>
        </div>
        <div className="section-content">
          <div className="work-exp">
            {workItems.map((wi, i) => 
              <WorkItem workItem={wi}></WorkItem>
            )}
          </div>
        </div>
      </div>
      <div id="contact" className="section">
        <div className="section-heading">
          <h2>Contact</h2>
        </div>
        <div className="section-content">
          <div className="contact-block">
            <button onClick={()=> {openInNewTab(file)}}>Resume</button>
            <button onClick={()=> {openInNewTab('mailto:nick@nstuttle.com?subject=Re:Nstuttle.com')}}>Email</button>
            <button onClick={()=> {openLinkedIn()}}>LinkedIn</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
