import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReact, faAngular, faCss3, faHtml5, faNode, faGoogle, faFigma, faMicrosoft, faWordpress, faSquarespace} from "@fortawesome/free-brands-svg-icons"
import { faLink } from "@fortawesome/free-solid-svg-icons";


const WorkItem = ({workItem}: any) => {
    return (
        <div className="work-item section">
            <div className="section-heading">
                <h4>{workItem?.name} <span className="light-text">({workItem?.workDate})</span> {workItem?.siteLink && <a className="work-link" href={workItem?.siteLink} target="_blank" title={workItem?.siteLink} rel="noreferrer"><FontAwesomeIcon className="work-link-i" icon={faLink}/></a>}</h4>
                {workItem?.title && <h4 className="work-title light-text">{workItem?.title}</h4>}
            </div>
            <div className="section-content">
                <p>{workItem?.description}</p>
                <div className="used-tech">
                    {
                        workItem?.workTechnologies?.length && workItem?.workTechnologies?.map((i: string) => {
                            return ({
                                'react': <FontAwesomeIcon icon={faReact} title="React"/>,
                                'angular': <FontAwesomeIcon icon={faAngular} title="Angular16, AngularJS" />,
                                'css': <FontAwesomeIcon icon={faCss3} title="CSS3, SCSS" />,
                                'html': <FontAwesomeIcon icon={faHtml5} title="HTML" />,
                                'node': <FontAwesomeIcon icon={faNode} title="Node" />,
                                'google': <FontAwesomeIcon icon={faGoogle} title="Google, Firebase" />,
                                'figma': <FontAwesomeIcon icon={faFigma} title="Figma, XD" />,
                                'microsoft': <FontAwesomeIcon icon={faMicrosoft} title="Microsoft, Azure, DevOps" />,
                                'wordpress': <FontAwesomeIcon icon={faWordpress} title="Wordpress, PHP" />,
                                'squarespace': <FontAwesomeIcon icon={faSquarespace} title="Squarespace" />,
                            }[i])
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default WorkItem;