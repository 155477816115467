
class WorkItem {
    name?: string;
    title?: string;
    description?: string;
    workDate?: string;
    workTechnologies?: string[];
    siteLink?: string;
}

const WorkItems: WorkItem[] = [
    {
        name: "Mortgage365",
        title: "Frontend Lead, Sr. Fullstack",
        description: "Led architectural design and development efforts for Mortgage365's flagship product, overseeing the entire process from initial whiteboard concepts and wireframing through to delivering a polished product. Additionally, played a key role in crafting the UX and UI design. Conceptualized and delivered a multitude of transformative features, adopted by thousands of users within Fortune 500 and Fortune-recognized      industry leaders. Team leadership and mentoring. Cake maker and fun-haver.",
        workDate: "2017 - Present",
        workTechnologies: ['angular', 'css', 'html', 'microsoft', 'figma'],
        siteLink: "https://mortgage365.com/"
    },
    {
        name: "FlyFishFinder",
        title: "Frontend Developer, Contract",
        description: "Designed and developed a React.js (Typescript) web application, incorporating seamless integrations with Firebase Auth and Firestore, as well as MapBox mapping technology. Pioneered innovative architectural solutions and tools to enhance ROI, streamline application usability, and drive user adoption.",
        workDate: "2023 - Present",
        workTechnologies: ['react', 'css', 'html', 'google', 'figma'],
        siteLink: "https://flyfishfinder.com/"
    },
    {
        name: "HighPine Media",
        title: "Owner",
        description: "Provide web design and development services for small businesses and individuals. Create digital media and social content to enhance clients' online presence.",
        workDate: "2020 - Present",
        workTechnologies: ['angular','react', 'css', 'html', 'google', 'microsoft', 'node', 'squarespace', 'wordpress', 'figma'],
        siteLink: "https://highpinemedia.com/"
    },
    {
        name: "DirectNet",
        title: "Frontend Developer, Marketing",
        description: "Designed and maintained UX/UI-focused content for multiple high-traffic websites. Developed online, platform-based, and mobile tools for internal and consumer use. Implemented strategies for increased conversion and visibility.",
        workDate: "2015 - 2017",
        workTechnologies: ['angular', 'css', 'html', 'wordpress', 'microsoft', 'figma'],
        siteLink:"https://directnet.us/"
    }
]

export default WorkItems;